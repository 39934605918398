// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export type IconmonstrTwitter5SvgIconProps = React.ComponentProps<"svg"> & {
  title?: string
}

export function IconmonstrTwitter5SvgIcon(
  props: IconmonstrTwitter5SvgIconProps
) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      viewBox={"0 0 24 24"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm6.5 8.778a4.928 4.928 0 01-1.414.388 2.473 2.473 0 001.083-1.362 4.926 4.926 0 01-1.564.597 2.464 2.464 0 00-4.195 2.245 6.993 6.993 0 01-5.074-2.572 2.465 2.465 0 00.762 3.287 2.457 2.457 0 01-1.114-.308c-.027 1.14.791 2.207 1.975 2.445a2.467 2.467 0 01-1.112.042 2.465 2.465 0 002.3 1.709A4.955 4.955 0 016.5 16.27a6.963 6.963 0 003.773 1.106c4.572 0 7.154-3.861 6.998-7.324.482-.346.899-.78 1.229-1.274z"
        }
      ></path>
    </svg>
  )
}

export default IconmonstrTwitter5SvgIcon
/* prettier-ignore-end */
